.popover-arrow {
  --size: 10px;
  --offset: 10px;
  position: absolute;
  display: block;
  width: var(--size);
  height: var(--size);
  overflow: hidden;
  background: 0 0;
  pointer-events: none;
}
.popover-arrow-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: calc(var(--size) * 0.7);
  height: calc(var(--size) * 0.7);
  margin: auto;
  background-color: #fff;
  content: '';
  pointer-events: auto;
}

.arrow-top-left .popover-arrow {
  left: var(--offset);
  top: 0;
  transform: translateY(-100%);
}
.arrow-top-left .popover-arrow-content {
  transform: translateY(calc(var(--size) / 2)) rotate(45deg);
  box-shadow: 3px 3px 7px #00000012;
}

.arrow-top-center .popover-arrow {
  left: 50%;
  top: 0;
  transform: translate(-50%, -100%);
}
.arrow-top-center .popover-arrow-content {
  transform: translateY(calc(var(--size) / 2)) rotate(45deg);
  box-shadow: 3px 3px 7px #00000012;
}

.arrow-top-right .popover-arrow {
  right: var(--offset);
  top: 0;
  transform: translateY(-100%);
}
.arrow-top-right .popover-arrow-content {
  transform: translateY(calc(var(--size) / 2)) rotate(45deg);
  box-shadow: 3px 3px 7px #00000012;
}

.arrow-right-top .popover-arrow {
  right: 0;
  top: var(--offset);
  transform: translateX(100%);
}
.arrow-right-top .popover-arrow-content {
  transform: translateX(calc(var(--size) / -2)) rotate(45deg);
  box-shadow: 3px -3px 7px #00000012;
}

.arrow-right-center .popover-arrow {
  right: 0;
  top: 50%;
  transform: translate(100%, -50%);
}
.arrow-right-center .popover-arrow-content {
  transform: translateX(calc(var(--size) / -2)) rotate(45deg);
  box-shadow: 3px -3px 7px #00000012;
}

.arrow-right-bottom .popover-arrow {
  right: 0;
  bottom: var(--offset);
  transform: translateX(100%);
}
.arrow-right-bottom .popover-arrow-content {
  transform: translateX(calc(var(--size) / -2)) rotate(45deg);
  box-shadow: 3px -3px 7px #00000012;
}

.arrow-bottom-right .popover-arrow {
  right: var(--offset);
  bottom: 0;
  transform: translateY(100%);
}
.arrow-bottom-right .popover-arrow-content {
  transform: translateY(calc(var(--size) / -2)) rotate(45deg);
  box-shadow: 3px 3px 7px #00000012;
}

.arrow-bottom-center .popover-arrow {
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 100%);
}
.arrow-bottom-center .popover-arrow-content {
  transform: translateY(calc(var(--size) / -2)) rotate(45deg);
  box-shadow: 3px 3px 7px #00000012;
}

.arrow-bottom-left .popover-arrow {
  left: var(--offset);
  bottom: 0;
  transform: translateY(100%);
}
.arrow-bottom-left .popover-arrow-content {
  transform: translateY(calc(var(--size) / -2)) rotate(45deg);
  box-shadow: 3px 3px 7px #00000012;
}

.arrow-left-bottom .popover-arrow {
  left: 0;
  bottom: var(--offset);
  transform: translateX(-100%);
}
.arrow-left-bottom .popover-arrow-content {
  transform: translateX(calc(var(--size) / 2)) rotate(45deg);
  box-shadow: -3px 3px 7px #00000012;
}

.arrow-left-center .popover-arrow {
  left: 0;
  top: 50%;
  transform: translate(-100%, -50%);
}
.arrow-left-center .popover-arrow-content {
  transform: translateX(calc(var(--size) / 2)) rotate(45deg);
  box-shadow: -3px 3px 7px #00000012;
}

.arrow-left-top .popover-arrow {
  left: 0;
  top: var(--offset);
  transform: translateX(-100%);
}
.arrow-left-top .popover-arrow-content {
  transform: translateX(calc(var(--size) / 2)) rotate(45deg);
  box-shadow: -3px 3px 7px #00000012;
}
