.button-dropdown {
    .translate-down {
        transform: translateY(50%);
    }
    .min-width-100p {
        min-width: 100%;
    }
    .button-main{
        display: flex;
        align-items: center;
    }
}