.usa-button {
  &.usa-button {
    font-weight: bold;

    &--medium {
      font-size: 1rem;

      padding-top: 0.625rem;
      padding-bottom: 0.625rem;

      &:not(.usa-button--unstyled) {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
      }
    }
    &--small {
      font-size: 0.875rem;

      padding-top: 0.375rem;
      padding-bottom: 0.375rem;

      &:not(.usa-button--unstyled) {
        padding-left: 0.813rem;
        padding-right: 0.813rem;
      }
    }
    &--large {
      font-size: 1.375rem;

      padding-top: 0.75rem;
      padding-bottom: 0.813rem;

      &:not(.usa-button--unstyled) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
    &--outline {
      background-color: #fff;
    }
    &--unstyled {
      font-weight: normal;
    }
  }
}
