@import '@uswds/dist/scss/uswds';

.link-card-group {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  @media all and (min-width: 40em) {
    flex-direction: row;
  }

  .link-card {
    margin-bottom: 2rem;

    .link-card__container {
      padding: 1.5rem;
      max-width: none;
      @include u-bg('blue-5');
      @include u-radius(md);
      position: relative;
      display: grid;
      margin-left: 0.5rem;
      margin-right: 0.5rem;

      &.link-card__container__has-media {
        @include at-media($theme-card-flag-min-width) {
          grid-template-columns: 90px 1fr;
          grid-column-gap: 24px;
        }
      }

      h2 {
        margin: 0px;
        &.title-m{
          text-transform:capitalize;
        }
        a,
        a:visited,
        button {
          @include u-text('primary');
        }

        button {
          background-color: transparent;
          border: none;
          margin: 0;
          padding: 0;
          font-family: inherit;
          font-size: inherit;
          line-height: inherit;
          font-weight: inherit;
          text-transform: inherit;

          &:hover {
            cursor: pointer;
          }
        }

        a:hover,
        a:focus,
        button:hover,
        button:focus {
          color: #fff;
        }
      }
      p {
        margin-top: 1rem;
        margin-bottom: 0px;
      }

      &:focus-within,
      &:hover {
        box-shadow: 0 0 0 0.25rem;
        @include u-bg('primary');
        color: #fff;
      }

      &:focus-visible {
        outline: none;
      }

      &:focus-within a:focus,
      &:focus-within button:focus,
      a,
      button {
        text-decoration: none;
      }

      a:focus,
      button:focus {
        outline: none;
        text-decoration: underline;
      }

      a::after,
      button::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
}
