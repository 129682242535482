@import '@uswds/dist/scss/uswds';

.RichEditor-root {
  @include u-border('1px');
  @include u-border('base');

  @include u-font('body', '2xs');
  overflow-y: auto;

  .editor-body {
    @include u-padding('105');
    //160px
    @include u-minh('card');
    //240px
    @include u-maxh('card-lg');
    overflow-x: scroll;
  }
  .RichEditor-editor {
    cursor: text;
    @include u-font('sans', 'sm');
    @include u-margin-top('105');
  }

  .RichEditor-blockquote {
    @include u-border-left('base-lighter');
    @include u-border-left('05');
    @include u-color('gray-warm-50');
    @include u-font-family('sans');
    font-style: italic;
    @include u-padding-right('105');
    @include u-padding-left('105');
    @include u-padding-top('205');
    @include u-padding-bottom('205');
  }
  .RichEditor-controls {
    @include u-border-bottom('base');
    @include u-border-bottom('1px');
    width: 100%;
    .text-icon {
      @include u-width(2.5);
    }
  }
  .editor-header {
    display: flex;
  }
}
.RichEditor-styleButton {
  @include u-color('primary-dark');
  cursor: pointer;
  @include u-padding('105');
  display: inline-block;
  font-weight: bolder;

  &:hover {
    @include u-bg('base-lighter');
  }
}
.RichEditor-activeButton {
  @include u-bg('base-lighter');
}
