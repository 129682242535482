.address-form-group {
  .combo-box--error {
    input {
      border-width: 0.25rem;
      border-style: solid;
      border-color: #b50909;
    }
  }
  
  .usa-form-group--error {
    margin-top: 1.5rem !important;
  }
}

.address-form-error-border {
  border-left: 0.25rem solid #b50909;
  padding-left: 1rem;
  margin-left: -1.25rem;

  .usa-combo-box__input {
    border: 0.25rem solid #b50909;
  }
}
