$container-width: 15rem;
$trigger-height-mobile: 4rem;
$trigger-height-desktop: 2rem;
$trigger-fullname-maxwidth: 40rem;
$u-text-bold: 700;

.user-menu__wrapper {
  .user-menu__trigger {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    .user-menu__trigger-fullname {
      // @include u-text('bold');
      font-weight: $u-text-bold;
      text-transform: capitalize;
      margin-left: 0.5rem;
      margin-right: 0.25rem;
      max-width: $trigger-fullname-maxwidth;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .user-menu__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 999;
    top: 0;
    right: 0;
    background-color: var(--theme-color-primary-darker);
    color: #fff;
    padding-top: 2rem;

    // @include u-font('body', '2xs');
    // @include u-line-height('body', 3);
    font-size: 0.93rem;
    line-height: 1.3;

    .user-menu__title {
      text-transform: capitalize;
      text-align: center;
      word-break: break-word;
      margin: 0;
      padding: 0 0 1rem 0;

      // @include u-font('body', 'md');
      // @include u-text('bold');
      font-size: 1.13rem;
      font-weight: $u-text-bold;
    }

    .user-menu__list {
      flex: 1;
      width: 100%;

      .user-menu__close,
      .user-menu__close:hover {
        display: block;
        position: absolute;
        width: 3rem;
        height: 3rem;
        top: 0.25rem;
        right: 0.25rem;
        color: #fff;
        padding: 0.5rem;
      }

      .user-menu__menuitem {
        width: 100%;
        color: #fff;
        background-color: var(--theme-color-primary-darker);
        display: flex;
        text-decoration: none;
        padding: 1rem;
        align-items: center;
        > :nth-child(1) {
          flex-grow: 1;
        }

        .user-menu__menuitem-icon {
          width: 1.25rem;
          height: 1.25rem;
        }

        &.has-separator {
          border-top: 1px solid var(--theme-color-primary-lighter);
        }
      }
      .user-menu__menuitem:hover,
      .user-menu__menuitem:focus {
        background-color: var(--theme-color-primary-dark);
      }
    }
  }
}

@mixin menu-trigger-mobile() {
  .user-menu__wrapper {
    .user-menu__trigger {
      width: $trigger-height-mobile;
      height: $trigger-height-mobile;

      .user-menu__trigger-icon {
        display: inline;
      }
      .user-menu__trigger-fullname,
      .user-menu__trigger-chevron {
        display: none;
      }
    }

    .user-menu__is-open {
      visibility: visible;
    }
  }
}

@mixin menu-trigger-desktop() {
  .user-menu__wrapper {
    .user-menu__trigger {
      width: auto;
      height: $trigger-height-desktop;
      border: none;

      .user-menu__trigger-icon,
      .user-menu__trigger-fullname,
      .user-menu__trigger-chevron {
        display: inline;
      }
    }

    .user-menu__is-open {
      visibility: hidden;
    }
  }
}

@mixin menu-container-mobile() {
  .user-menu__wrapper {
    .user-menu__overlay {
      opacity: 0.7;
      visibility: visible;
    }

    .user-menu__container {
      position: fixed;
      top: 0;
      width: $container-width;
      height: 100vh;
      animation: slidein-left 0.3s ease-in-out;
    }
  }
}

@mixin menu-container-desktop() {
  .user-menu__wrapper {
    .user-menu__overlay {
      visibility: hidden;
    }

    .user-menu__container {
      position: absolute;
      top: -1.5rem;
      width: $container-width;
      height: auto;
      animation: none;
    }
  }
}

// tempfix for sass duplicate import, will revert back after uswds 3.0 is released
// @include at-media('mobile')
// @include at-media('tablet')
@media only screen and (max-width: 1023px) {
  @include menu-trigger-mobile();
  @include menu-container-mobile();
}
// tempfix for sass duplicate import, will revert back after uswds 3.0 is released
// @include at-media('desktop')
@media only screen and (min-width: 1024px) {
  @include menu-trigger-desktop();
  @include menu-container-desktop();
}
