.afp-notification {
  width: 100%;
  margin-top: 3px;

  @media screen and (max-width: 480px) {
    background-image: none !important;

    .afp-notification__body {
      padding-left: 0 !important;
    }
  }

  .afp-notification__body {
    display: flex;
    flex-direction: row;
    align-items: center;
    .afp-notification__description {
      margin: 0;
    }
    h6.afp-notification__body__timeoffset {
      margin: 0;
    }

    .afp-notification__body__timeoffset {
      white-space: nowrap;
      padding: 0 1rem;
    }

    .afp-notification__body__button {
      padding: 0.5em 1.5rem;
      font-size: 14px;
    }

    .afp-notification__action_button {
      min-width: 7.5rem;
    }

    .afp-notification__offset_container {
      min-width: 8rem;
    }

    .afp-notification__status_tag span {
      float: right;
    }
  }
  .afp-notification__new_tag {
    background: #dcdee0;
    color: black;
  }
}
