.typeahead-container .main_input {
  left: 0;
  right: 0;
  z-index: 2 !important;
}

.typeahead-container .ghost {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  z-index: 1 !important;
}

.typeahead-container .opts-container {
  background: white;
  border: 1px solid black;
  position: absolute;
  z-index: 3;
  top: -1px;
  min-width: 100%;
  max-height: 30rem;
  overflow-y: auto;
  padding: 5px;
}

.typeahead-container .typeahead-option {
  padding: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  min-width: 100%;
  cursor: pointer;
  background: white;
}

.typeahead-container .typeahead-option:focus {
  outline: 0.3rem solid #2491ff;
}

.typeahead-container .typeahead-option:last-of-type {
  border-bottom: 0;
}

.typeahead-container .typeahead-option:hover {
  background: #d9e8f6;
}
.typeahead-container .typeahead-option.unselectable {
  cursor: default;
}
.typeahead-container .typeahead-option.unselectable:hover {
  background: white;
}

.typeahead-container .typeahead-option.loading {
  display: flex;
  align-items: center;
}
.typeahead-container .typeahead-option.loading .afp-spinner {
  margin-right: 0.5rem;
}

.usa-accordion__content {
  overflow: visible;
}

.typeahead-container {
  position: relative;
  max-width: 30rem;
}

.afp-typeahead-options-wrapper {
  position: relative;
  display: block;

  &-hide {
    display: none;
  }
}

.typeahead-prefix-container {
  width: 100%;
  position: relative;
  display: inline-block;

  button {
    position: absolute;
    margin-top: 0 !important;
    right: 0.25rem !important;
    top: 0.75rem !important;
    cursor: pointer;
    background-color: #fff;
    padding: 0.3rem !important;

    &:hover {
      background-color: #fff;
    }
  }

  input::placeholder {
    padding-left: 1.2rem;
  }

  .prefix-icon {
    position: absolute;
    top: 1.2rem;
  }

}
.matching-results-summary {
  display: none;
}
