@import '@uswds/dist/scss/uswds';

$lightBlue: color($theme-color-primary);
$breakpoint: 1023px;

// Override usdws menu trigger as per Figma
.usa-menu-btn {
  background-color: transparent !important;
  cursor: pointer;
  .megaMenu-hamburger-icon {
    color: $lightBlue;
    fill: $lightBlue;
    height: 3em;
    width: 3em;
  }
}

.usa-nav__primary-item {
  position: relative;
}

// tempfix for sass duplicate import, will revert back after uswds 3.0 is released
// @include at-media('desktop')
@media only screen and (min-width: 1024px) {
  .usa-nav__submenu {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    font-size: 0.875rem;
    padding: 1rem 1.125rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    min-width: 15rem;
    max-width: 90rem; //Testing
    width: -moz-fit-content;
    width: fit-content;
    // this is an issue as the parent li item ( main menu ) has the actual width

    .usa-nav__submenu-item {
      a {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }
  }

  // the following styles override the default background image for chevron to psuedo content to fix the 508 defect
  .usa-nav__primary-item > button {
    background-image: none !important; //override the USWDS for 508 issue of background image

    span {
      padding-right: 0.5rem !important;
    }

    &[aria-expanded='false'] {
      &:hover::after,
      &::after {
        content: '';
        position: relative !important;
        background-color: transparent !important;
        width: 0.5rem !important;
        height: 0.5rem !important;
        background: transparent;
        border: 2px solid color($nav-link-color);
        border-left-color: transparent;
        border-top-color: transparent;
        transform: rotate(45deg) translate(-25%, -15%);
        display: inline-block !important;
        left: 0 !important;
        right: 0 !important;
      }

      &:hover {
        background-image: none !important; //override the USWDS for 508 issue of background image
        &::after {
          border-right-color: color($theme-color-primary);
          border-bottom-color: color($theme-color-primary);
        }
      }
    }

    &[aria-expanded='true'] {
      &::after {
        content: '';
        position: relative !important;
        background-color: transparent !important;
        width: 0.5rem !important;
        height: 0.5rem !important;
        background: transparent;
        border: 2px solid #fff;
        border-left-color: transparent;
        border-top-color: transparent;
        transform: rotate(225deg) translate(-25%, -15%);
        display: inline-block !important;
        left: 0 !important;
        right: 0 !important;
      }
    }
  }
}
