// .usa-logo.afp-logo {
//   margin-top: 20px;
//   margin-bottom: 20px;
//   line-height: 0.8;
// }

.usa-logo.afp-logo {
  img {
    width: 152px;
    height: 72px;
    max-width: none;
  }
}
