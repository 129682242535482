.afp-spinner {
  display: flex;
  justify-content: center;  
}

.spinner-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__small::after {
    width: 1rem;
    height: 1rem;
    border: 0.188rem solid var(--theme-link-reverse-active-color);
  }
  &__medium::after {
    width: 1.375rem;
    height: 1.375rem;
    border: 0.281rem solid var(--theme-link-reverse-active-color);
  }
  &__large::after {
    width: 2.874rem;
    height: 2.874rem;
    border: 0.469rem solid var(--theme-link-reverse-active-color);
  }
  &::after {
    content: '';
    border-top-color: var(--theme-color-primary-light);
    border-radius: 50%;
    transform: rotate(0.16turn);
    animation: spinner-loading 0.8s linear infinite;
  }
}

@keyframes spinner-loading {
  /* Safari support */
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.afp-spinner-border {
  border-radius: 50%;
  background: var(--theme-color-primary);
  &__small {
    width: 1.5rem;
    height: 1.5rem;
    border: 0.25rem solid transparent;
  }
  &__medium {
    width: 2rem;
    height: 2rem;
    border: 0.313rem solid transparent;
  }
  &__large {
    width: 4rem;
    height: 4rem;
    border: 0.563rem solid transparent;
  }
}
