.table__filter-icon {
  display: flex;
}

.table-sort-order {
  display: inline-block;
}

.table__sort-icon {
  display: inline-block;
  width: 1.5rem;
  text-align: center;
  padding-top: 0.1rem;
}

.table-filters td {
  background: #eff6fb !important;
}

.table-header {
  display: flex;
}

.afp-table-header__sort-button {
  background: transparent;
  border: 0;
  cursor: pointer;
  font-weight: 700;
}
