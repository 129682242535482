@import '@uswds/dist/scss/uswds';

.react-tabs__tab {
  @include u-border(1px, 'base-lighter');
  border-radius: 0;
  border-right: 0;
  padding: 0.75rem 1rem;
  font-weight: 600;
  cursor: pointer;
}

.react-tabs__tab:last-child {
  @include u-border(1px, 'base-lighter');
  @include u-border-right(1px, 'base-lighter');
  border-radius: 0;
}

.react-tabs__tab--selected {
  @include u-text('blue-60v');
  box-shadow: 0 4px inset #005ea2;
  border-radius: 0 !important;
  border-bottom: 2px solid white !important;
}

.react-tabs__tab-list {
  display: flex;
  box-shadow: 0px -1px 0 inset #dddddd;
  list-style: none;
  li {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  padding-left: 0;
  padding-right: 0;
}

.justify-right {
  display: flex;
  box-shadow: 0px -1px 0 inset #dddddd;
  list-style: none;
  li {
    display: inline-block;
  }
  padding-right: 0;
  justify-content: right;
}

.count-tag-zero {
  background-color: #f0f0f0;
  color: black;
}

.space-between {
  justify-content: space-between;
}

.afp-vehicle-counters * {
  margin-right: 3rem;
}

.afp-vehicle-counters *:last-child {
  margin-right: 0 !important;
}
