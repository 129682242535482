@import '@uswds/dist/scss/uswds';

$modal-default: 480px;
$modal-large: 764px;
$modal-extra-large: 1050px;

.active-modal {
  overflow: hidden;
}

.afp-modal-wrapper {
  visibility: visible;
  opacity: 1;
  position: fixed;
  z-index: 99999;
  text-align: center;
  transition: opacity .15s ease-in-out;
}

.afp-modal-overlay {
  background: rgba(0,0,0,.7);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: scroll;
  overflow-x: hidden;
  padding: 1.5rem;
  position: fixed;
  scroll-behavior: smooth;
  top: 0;
  width: 100%;
  z-index: 999;
}

.afp-modal-overlay:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.afp-modal {

  @include u-bg('white');
  @include u-shadow(3);
  @include u-position('relative');
  width: $modal-default;
  display: inline-block;
  margin: 1.25rem auto;
  position: relative;
  text-align: left;
  vertical-align: middle;
  z-index: 1000;

  .modal-inner {
    @include u-padding-x(4);
  }

 .title {
    @include u-margin-top(5);
    @include u-display('block');
    h2 {
      margin: 0;
      @include typeset('sans', 'sm', 3);
      @include u-text(700);
    }
  }

  .modalClose {
    @include u-position('absolute');
    @include u-top(2);
    @include u-right(2);
    @include u-padding(0);
  }

  .actions {
    @include u-margin-top(4);
    @include u-padding-x(4);
    @include u-height(80px);
    @include u-display('flex');
    @include u-flex('row');
    align-items: center;
  }

  .content,
  .actions button{
    @include u-font('sans', '2xs');
    @include u-margin-right(1);
    @include u-margin-y(1);
    @include u-text('left');
  }

  &.large {
    width: $modal-large;
    .title {
      @include u-margin-top(6);
      h2 {
        @include typeset('sans', lg, 4);
        @include u-text(900);
      }
    }
    .actions {
      @include u-margin-top(5);
      @include u-height(96px);
    }
    .content,
    .actions button{
      @include u-font('sans', 'md');
    }
  }

  &.extra-large {
    width: $modal-extra-large;
    .title {
      @include u-margin-top(7);
      h2 {
        @include typeset('sans', xl, 3);
        @include u-text(700);
      }
    }
    .actions {
      @include u-margin-top(9);
      @include u-height(96px);
    }
    .content,
    .actions button{
      @include u-font('sans', 'md');
    }
  }
}

@include at-media-max('tablet-lg') {
  .afp-modal {
    width: 100% !important;
    .afp-modal-overlay:before {
      display: initial !important;
    }
    .actions {
      @include u-flex('column');
    }
    .title h2,
    .content,
    .actions button {
      @include u-text('center');
      @include u-margin(0);
    }
  } 
}
