.filter-panel {
  transition: 0.5s;
  overflow: visible;

  &-hidden {
    width: 0;
    height: 0;
    overflow: hidden;
    transition: 0.5s;
  }
}

.table-panel {
  transition: 0.5s;
  overflow: hidden;
}

@media (prefers-reduced-motion) {
  .filter-panel {
    transition: none;

    &-hidden {
      transition: none;
    }
  }

  .table-panel {
    transition: none;
  }
}

.filter-frame-upper-left {
  min-height: 2.5rem;
}

.filter-frame-filler {
  border-top: 1px solid var(--base-lighter);
}
