/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS 2.9.0
----------------------------------------
COMPONENT SETTINGS
----------------------------------------
Read more about settings and
USWDS style tokens in the documentation:
https://designsystem.digital.gov/design-tokens
----------------------------------------
*/

// Accordion
$theme-accordion-border-width: 0.5;
$theme-accordion-border-color: "base-lightest";
$theme-accordion-font-family: "body";

// Alert
$theme-alert-bar-width: 1;
$theme-alert-font-family: "ui";
$theme-alert-icon-size: 4;
$theme-alert-padding-x: 2.5;

// Banner
$theme-banner-background-color: "base-lightest";
$theme-banner-font-family: "ui";
$theme-banner-link-color: default;
$theme-banner-max-width: "widescreen";

// Breadcrumb
$theme-breadcrumb-background-color: "white";
$theme-breadcrumb-font-size: "sm";
$theme-breadcrumb-font-family: "body";
$theme-breadcrumb-link-color: default;
$theme-breadcrumb-min-width: "mobile-lg";
$theme-breadcrumb-padding-bottom: 2;
$theme-breadcrumb-padding-top: 2;
$theme-breadcrumb-padding-x: 0;
$theme-breadcrumb-separator-color: "base";

// Button
$theme-button-font-family: "ui";
$theme-button-border-radius: "md";
$theme-button-small-width: 6;
$theme-button-stroke-width: 2px;

// Card
$theme-card-border-color: "base-lighter";
$theme-card-border-radius: "lg";
$theme-card-border-width: 2px;
$theme-card-gap: 2;
$theme-card-flag-min-width: "tablet";
$theme-card-flag-image-width: "card-lg";
$theme-card-font-family: "body";
$theme-card-header-typeset: "heading", "lg", 2;
$theme-card-margin-bottom: 4;
$theme-card-padding-perimeter: 3;
$theme-card-padding-y: 2;

// Footer
$theme-footer-font-family: "body";
$theme-footer-max-width: "widescreen";

// Form and input
$theme-checkbox-border-radius: "sm";
$theme-form-font-family: "ui";
$theme-input-line-height: 3;
$theme-input-max-width: "mobile-lg";
$theme-input-select-border-width: 2px;
$theme-input-select-size: 2.5;
$theme-input-state-border-width: 0.5;

// Header
$theme-header-font-family: "ui";
$theme-header-logo-text-width: 33%;
$theme-header-max-width: "widescreen";

// Hero
//$theme-hero-image: "#{$theme-image-path}/hero.png";

// Identifier
$theme-identifier-font-family: "ui";
$theme-identifier-background-color: "base-darkest";
$theme-identifier-identity-domain-color: "base-light";
$theme-identifier-max-width: "widescreen";
$theme-identifier-secondary-link-color: "base-light";

// Navigation
$theme-navigation-font-family: "ui";
$theme-megamenu-columns: 3;

// Search
$theme-search-font-family: "ui";
$theme-search-min-width: 27ch;

// Sidenav
$theme-sidenav-current-border-width: 0.5;
$theme-sidenav-font-family: "ui";

// Step indicator
$step-indicator-background-color: "white";
$theme-step-indicator-counter-gap: 0.5;
$theme-step-indicator-counter-border-width: 0.5;
$theme-step-indicator-font-family: "ui";
$theme-step-indicator-heading-color: "ink";
$theme-step-indicator-heading-font-family: "ui";
$theme-step-indicator-heading-font-size: "lg";
$theme-step-indicator-heading-font-size-small: "md";
$theme-step-indicator-label-font-size: "sm";
$theme-step-indicator-min-width: "tablet";
$theme-step-indicator-segment-color-pending: "base-lighter";
$theme-step-indicator-segment-color-complete: "primary-darker";
$theme-step-indicator-segment-color-current: "primary";
$theme-step-indicator-segment-gap: 2px;
$theme-step-indicator-segment-height: 1;
$theme-step-indicator-text-pending-color: "base-dark";

// Tooltips
$theme-tooltip-background-color: "ink";
$theme-tooltip-font-color: "base-lightest";
$theme-tooltip-font-size: "xs";
