.afp-pagination {
  .usa-button {
    height: 2.7rem;
    padding: 0.4rem 0.9rem !important;
    border-radius: 0;
    font-size: 1rem;
  }
  .pagination-items-per-page-selector {
    display: none;
  }
  .page-selector-button {
    display: none;
  }
  .pagination-page-counter {
    align-items: center;
  }
  .no-navigator {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
    align-items: end;
  }
  .page-navigator ul{
    justify-content: center;
  }

  // Desktop size and above
  @media (min-width: 1039px) {
    .pagination-items-per-page-selector {
      display: block;
    }
    .page-selector-button {
      display: block;
    }
    .page-selector-dropdown {
      display: none;
    }
  }

  @media (max-width: 1039px) {
    .page-navigator {
      justify-content: center;
    }
  }

  // Size below mobile-lg
  @media (max-width: 480px) {
    .usa-button {
      margin-right: 0;
    }
    .page-selector-dropdown {
      margin: 0;
    }
    .pagination-items-per-page-selector {
      display: none;
    }
    .page-navigator {
      justify-content: center;
    }
  }

  .hide {
    display: none;
  }
}
