

.menu-dialog-action {
  color: inherit;
  text-decoration: none !important;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.625rem;
}
.menu-dialog-action:hover {
  text-decoration: none;
}

.menu-dialog {
  max-width: 23.125rem;
  width: max-content;
  li {
    cursor: pointer;
    color: var(--theme-link-color);
    padding: 0.75rem 1rem;
  }

  .menu-list-icon {
    margin-top: 0.25rem;
    color: var(--theme-link-color);
  }

  .button-label-menu-dialog {
    white-space: normal;
    line-height: 150%;
  }

  .items-list-hover {
    background: var(--theme-link-reverse-active-color);
    margin: 0;
    &:hover,
    &:focus-within {
      background: var(--theme-color-primary-lightest) !important;
    }
  }
}
