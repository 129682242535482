
$offWhite: #f7f7f7;

$site-primary-family: "blue-warm";
$site-primary-lighter: "#{$site-primary-family}-10";
$site-primary-light: "#{$site-primary-family}-30";
$site-primary: "#{$site-primary-family}-50";
$site-primary-vivid: "#{$site-primary-family}-50v";
$site-primary-dark: "#{$site-primary-family}-70";
$site-primary-darker: "#{$site-primary-family}-80";
$site-ink: "black-transparent-80";

$site-border-width: 2px;
$site-base-transparent-light: "black-transparent-10";
$site-background-color: "gray-1";

// non USWDS tokens
$theme-color-blue-2: #F9FBFD;
$theme-color-yellow-2: #FFFBE8;

// USWDS override for readonly and disabled form elements
.usa-input:disabled,
.usa-input:read-only,
.usa-textarea:disabled,
.usa-textarea:read-only,
.usa-select:disabled,
.usa-combo-box__input:disabled,
.usa-combo-box__input:read-only,
.disabled-control {
  @include u-bg('gray-3');
  border: none;
  background-image: none;
}

// USWDS override to remove the clear icon and dropdown arrow from the combo-box when it's disabled
.usa-combo-box.disabled {
  .usa-combo-box__clear-input__wrapper,
  .usa-combo-box__input-button-separator,
  .usa-combo-box__toggle-list__wrapper {
    display: none;
  }
}


.bg-blue-2 {
  background-color: $theme-color-blue-2;
}

.bg-yellow-2 {
  background-color: $theme-color-yellow-2;
}

.calendar-bg {
  @include u-bg("gray-warm-4");
}
.calendar-z-index {
  @include u-z('top');
}

.custom-color {
  @include u-text('primary-lighter');
  @include u-bg('primary-darker')
}

.title-xl {
  @include typeset('sans', 2xl, 3);
  @include u-text(700);
}

.title-l {
  @include typeset('sans', xl, 3);
  @include u-text(700);
}

.title-m {
  @include typeset('sans', lg, 3);
  @include u-text(400);
}

.title-m-bold {
  @include typeset('sans', lg, 4);
  @include u-text(900);
}

.title-s {
  @include typeset('sans', sm, 3);
  @include u-text(700);
}

.title-s-caps {
  @include typeset('sans', xs, 3);
  @include u-text(900);
  @include u-text('uppercase');
}

.title-xs {
  @include typeset('sans', 2xs, 3);
  @include u-text(700);
}

.body-tight {
  @include u-line-height('sans', 3);
}

.body-block {
  @include u-line-height('sans', 3);
  @include u-margin(0)
}

.body-bold {
  @include u-text(700);
}

.body-underline {
  @include u-text('underline');
}

.body-mono {
  @include u-font('mono', 'sm');
}

.body-small {
  @include u-font('sans', 'xs');
}

.body-small-tight {
  @include u-font('sans', 'xs');
  @include u-line-height('sans', 3);
}

ol {
  ol {
    list-style-type: lower-alpha;
    ol {
      list-style-type: decimal;
    }
  }
}

.docs-grid-example {
  @include u-font("mono", 4);
  padding-left: 2rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
  .grid-row {
    margin-top: units(1);
  }

  [class*="grid-col"] {
    background-color: color($site-primary-lighter);
    text-align: center;
  }

  .grid-row:not([class*="grid-gap"]) {
    [class*="grid-col"] {
      border: units(1px) solid;
      padding: units(2);
    }
  }

  [class*="grid-gap"] {
    [class*="grid-col"] > * {
      background-color: color("white");
      border: units(1px) solid;
      padding: units(2);
    }
  }
}
