.afp-table__expander-col {
    border-right: none !important;
}

.afp-table__expanded-row td {
    border-bottom: none !important;
    background: rgb(228, 242, 247) !important;
}

.afp-table__expanded-subrow td {
    border-top: none !important;
    background: rgb(248, 251, 253) !important;
}

tr > .afp-table__subrow {
    border-left: none !important;
}

.usa-table--full-width {
    width: 100%;
}