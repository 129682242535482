.afp-chip {
  display: inline-block;
  background-color: var(--theme-color-primary-lightest);
  border-radius: 0.3125rem;
  border: none;
  min-height: 1.9375rem; // 31px
  margin: 0;
  padding: 0.375rem 0.5rem;

  .afp-chip__container {
    display: flex;
    flex-direction: row;
    align-items: center;

    > label {
      max-width: 6.75rem; // 108px
      text-align: left;
    }
    .afp-chip__label-wrap {
      word-wrap: break-word;
    }
    .afp-chip__label-nowrap {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > button {
      padding: 0;
      border: none;
      background-color: inherit;
      cursor: pointer;
      margin-left: 0.375rem;

      .afp-chip__close {
        display: flex;
        justify-content: center;
        align-items: center;
        $close-button-width: 1.125rem;
        width: $close-button-width;
        height: $close-button-width;
        border-radius: calc(#{$close-button-width}/ 2);
        color: #fff;
        background-color: var(--theme-color-primary);
      }
    }
    > button:hover,
    > button:focus {
      .afp-chip__close {
        background-color: var(--theme-color-primary-darker);
      }
    }
  }
}
