// only to inject uswds with our custom overrides
@import '../styles/scss/styles.scss';

// tempfix: declaring variables to remove the dependency on uswds variables and sass variables
:root {
  --theme-color-primary: #{color($theme-color-primary)};
  --theme-color-primary-dark: #{color($theme-color-primary-dark)};
  --theme-color-primary-darker: #{color($theme-color-primary-darker)};
  --theme-color-secondary-dark: #{color($theme-color-secondary-dark)};
  --gray-90: #{color('gray-90')};
  --gray-20: #{color('gray-20')};
  --gray-10: #{color('gray-10')};
  --gray-cool-50: #{color('gray-cool-50')};
  --gray-cool-10: #{color('gray-cool-10')};
  --theme-link-color: #{color($theme-link-color)};
  --theme-link-reverse-active-color: #{color($theme-link-reverse-active-color)};
  --theme-color-primary-lightest: #{color($theme-color-primary-lightest)};
  --theme-color-primary-lighter: #{color($theme-color-primary-lighter)};
  --theme-color-primary-light: #{color($theme-color-primary-light)};
  --theme-table-stripe-background-color: #{color(
      $theme-table-stripe-background-color
    )};
  --base-lighter: #{color('base-lighter')};
  --nav-link-color: #{color($nav-link-color)};
  --theme-color-base: #{color($theme-color-base)};
  --theme-color-success-dark-vivid: #{color($theme-color-success-dark-vivid)};
  --theme-color-secondary-dark: #{color($theme-color-secondary-dark)};
  --theme-link-reverse-active-color: #{color($theme-link-reverse-active-color)};
  --theme-font-weight-bold: #{$theme-font-weight-bold};
  --theme-color-error: #{color($theme-color-error)};
  --theme-color-base-lightest: #{color($theme-color-base-lightest)};
}

body.sb-show-main {
  background-color: white;
}

a {
  color: var(--theme-link-color);
}
