.afp-font-size-08 {
  font-size: 0.8rem;
}

.applied-filter-accordion > h2 > button {
  color: var(--theme-color-primary);
  background-color: var(--theme-color-primary-lightest);

  &:hover {
    color: var(--theme-color-primary);
    background-color: var(--theme-color-primary-lighter);
  }
}

.filter-reset-button {
  padding-top: 1.25rem;
}

.filter-header-main {
  display: flex;
  justify-content: space-between;
}

.filter-chip-group-header {
  border-bottom: 1px solid #dfe1e2;
  margin-bottom: 0.25rem;
}

.usa-accordion__heading {
  button:not([disabled]):focus {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
}

.accordian-filter-border-top {
  border-top: 1px solid var(--base-lighter);
}

.filter-panel-horizontal {
  .usa-form-group {
    margin: 0;
  }
}
