

.fullWidth {
  // align-self: stretch;
  // display: flex;
  padding-right: 0.25rem;
  // flex-direction: column;
  // justify-content: space-between;
}

.between {
  justify-content: space-between;
  div:not(:last-child) {
    margin-right: 0.25rem;
  }
}

.side {
  justify-content: flex-start;
  div:not(:last-child) {
    margin-right: 0.25rem;
  }
}

.equalTwo {
  align-items: stretch;
  justify-content:space-evenly;
  div:not(:last-child) {
    margin-right: 0.25rem;
  }
  div {
    display: inline-block;
    width: 50%;
    align-self: stretch;
  }
}

.equalFour {
  align-items: stretch;
  justify-content:space-evenly;
  div:not(:last-child) {
    margin-right: 0.25rem;
  }
  div {
    display: inline-block;
    width: 25%;
    align-self: stretch;
  }
}

.equal {
  align-items: stretch;
  justify-content:space-evenly;
  div:not(:last-child) {
    margin-right: 0.25rem;
  }
  div {
    display: inline-block;
    width: 33.3%;
    align-self: stretch;
  }
}
