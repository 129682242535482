.contain_activity-trackr {
  position: relative;
  margin-top: 2.375rem;
  padding-left: 0.313rem;
  font-weight: normal;

  &:not(.highlight)::before {
    content: '';
    position: absolute;
    left: 0.906rem;
    top: 0.06rem;
    width: 11px;
    height: 100%;
    background-color: var(--gray-20);
    z-index: -1;
  }

  .activity-card-wrapper {
    display: flex;
    padding-top: 0;
    padding-bottom: 1.563rem;
    width: 100% !important;
  }

  .dot {
    width: 1.875rem;
    height: 1.875rem;
    margin-right: 0.938rem;
    border: 0.375rem solid white;
    border-radius: 2.5rem;
    background-color: var(--gray-cool-50);
    line-height: 0.125rem;
  }

  .activity-card {
    margin-top: 0.313rem;
    padding: 1.563rem 1.875rem;
    border-radius: 0.25rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border: 0.063rem solid var(--gray-cool-10);
    width: 100%;
    max-width: 935px;
    flex-grow: 1;

    &.default {
      background-color: rgba(196, 196, 196, 0.1);
    }

    &.blue {
      background-color: #eff6fb;
    }

    &-header {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &-title {
        display: flex;
        align-items: center;
        gap: 15px;

        h1 {
          font-size: 19px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: 0em;
          text-align: left;
          margin: 0;
          padding: 0;
        }
      }

      &-user-info {
        display: flex;
        align-items: center;

        &-icon {
          margin-right: 9px;
          display: inline-block;
          color: #565c65;
        }

        &-name {
          font-size: 16px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }

    &-comments {
      h2 {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0;
        padding: 0;
        margin-top: 12px;
        margin-bottom: 12px;
        padding-bottom: 6px;
        border-bottom: 0.063rem solid var(--gray-20);
      }

      &.no-body {
        h2 {
          margin-top: 0;
        }
      }

      &-content {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0;
        padding: 0;

        &.clamp {
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          overflow-wrap: break-word;
        }
      }
    }

    &-action-links {
      display: flex;
      gap: 12px;

      &-separator {
        color: #000;
      }

      .usa-button {
        padding: 0;
      }
    }

    &-footer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }
  }

  &.highlight {
    .activity-card {
      background-color: white;
      border: 0.063rem solid var(--gray-10);

      &-header {
        flex-direction: row;

        &-title {
          flex: 1;
          gap: 4px;
          align-items: baseline;

          h1 {
            font-size: 16px;
          }

          &-tag {
            display: none;
          }
        }

        &-user-info {
          &-icon {
            display: none;
          }
        }
      }
    }
  }

  .flex-spacer {
    flex: 1;
    flex-grow: 1;
  }

  .activity-body {
    width: 100%;
    overflow-wrap: break-word;
    overflow: hidden;
  }
}
