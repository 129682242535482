

.interactive-checks {
  list-style: none;
  padding: 0;
  margin: 1rem 0 0 0;
  & > *+* {
    padding-top:0.75rem 
  }

  li {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 400;
  }

  .is-neutral {
    color: var(--theme-color-base);
  }

  .is-valid {
    color: var(--theme-color-success-dark-vivid);
  }

  .is-invalid {
    color: var(--theme-color-secondary-dark);
  }
}
