.afp-details-table {
  width: 100%;
  border-collapse: collapse;
  tbody {
    td.wrapText{
      word-break: break-all;
    }
  }
  th,
  td {
    padding: 0.5rem 0;
  }
}
