.justify-space-between {
  justify-content: space-between;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.break-all {
  word-break: break-all;
}

.action-btn {
  padding: 0 !important;
}

.file-icon {
  top: 0.1rem;
}

.usa-file-input__wrapper {
  max-height: 100%;

  .usa-file-input {
    max-height: 100%;
    display: flex;
    flex-direction: column;

    .usa-file-input__input:focus {
      border: 2px dashed #2491ff;
    }

    .usa-file-input__target {
      border: none !important;
      overflow-y: auto;
      display: flex;
      flex-direction: column;

      .usa-file-input__instructions {
        border: 1px dashed #a9aeb1;
        height: auto;
      }

      .usa-file-input__box {
        height: auto;
      }

      .usa-file-input__list {
        position: relative;
        z-index: 10;
        overflow-y: auto;
      }
    }
  }
}
