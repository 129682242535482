
.afp-sidenav {
  line-height: inherit !important;
  &.usa-sidenav__sublist li {
    padding-left: 1rem;
    &:last-child {
      border-bottom: none;
    }
  }
  .sidenav-item {
    padding: 0;
    a {
      padding: 0.8rem 0 0.8rem 1rem !important;
      &:hover {
        background-color: transparent;
        color: var(--theme-color-primary) !important;
      }
    }
    .sidenav-item-body {
      padding: 0;
      &:hover {
        background-color: var(--theme-color-base-lightest);
        ul.usa-sidenav__sublist {
          background-color: white !important;
        }
      }
      &.usa-current{
        background-color: transparent;
        a {
          color: var(--theme-color-primary);
        }
      }
    }
    .sidenav-item-counter {
      right: 2rem;
    }
  }
}

/* overrides */

#main-content .afp-sidenav{
  a:not(.usa-button) {
    color: var(--theme-base-dark) !important;
    &:hover {
      color: var(--theme-color-primary) !important;
    }
  }
  .usa-current a:not(.usa-button) {
    color: var(--theme-color-primary) !important;
  }
}

