.text-filter-container {
  position: relative;
  display: inline-block
}

.text-filter-container input {
  margin-top: 0 !important;
}

.text-filter-container .filter-clear-button {
  position: absolute;
  right: 0.5rem;
  top: 0.7rem;
  cursor: pointer;
}

.text-filter-container .searchIcon {
  position: absolute;
  top: 0.7rem;
}
