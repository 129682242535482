.afp-counter-tag {
  display: inline-block;
}
.afp-counter-tag__count {
  font-size: 0.875rem;
  height: 1.375rem;
  min-width: 1.438rem;
  display: inline-block;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  border-radius: 1.25rem;
}
