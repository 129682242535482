

.custom-breadcrumb-link {
  color: var(--theme-color-primary);
  display: inline;
  text-decoration: underline;
}
.usa-breadcrumb__list-item:not(:last-child)::after {
  margin: 0px 6px !important;
  -webkit-mask: url(../img/usa-icons/navigate_next.svg) no-repeat center/2ex 2ex;
  mask: url(../img/usa-icons/navigate_next.svg) no-repeat center/2ex 2ex;
}
