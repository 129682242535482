

.translate-left {
  transform: translateX(-100%);
  left: 100%;
}

.translate-top-left {
  transform: translate(-100%, -100%);
  left: 100%;
  top: 0;
}

.translate-top {
  transform: translateY(-100%);
  top: 0;
}

.menu-box:hover{
  background: var(--theme-link-color) !important;
  border-radius: 0.25rem;
  .action-icon{
    color: var(--theme-link-reverse-active-color);
  }
}