$header-min-height: 4rem;
$logo-width: 157px;
$logo-height: 72px;
$logo-mobile-ratio: 0.625;

.usa-header.usa-header--extended {
  border-bottom: 5px solid var(--theme-color-secondary-dark);
  min-height: $header-min-height;

  .usa-logo {
    max-width: 100%;
  }

  .usa-navbar {
    display: flex;
    width: 100%;
    height: 100%;
  }
}

.usa-nav__secondary-links a {
  cursor: pointer;
  text-decoration: underline !important;
  color: var(--theme-color-primary) !important;
}

// TODO: Fix when new menu nav is implemented.
@media only screen and (max-width: 1399px) {
  .usa-navbar {
    border-bottom: none !important;
  }
}

// tempfix for sass duplicate import, will revert back after uswds 3.0 is released
// @include at-media('mobile')
// @include at-media('tablet')
@media only screen and (max-width: 1023px) {
  .usa-header.usa-header--extended {
    .usa-logo.afp-logo {
      margin-top: 0.1875rem;
      img {
        width: calc(#{$logo-width} * #{$logo-mobile-ratio});
        height: calc(#{$logo-height} * #{$logo-mobile-ratio});
      }
    }
    .usa-header--user-menu {
      align-items: center;
      margin-bottom: 0;
      min-height: $header-min-height;
      height: 100%;
      border-left: 1px solid var(--base-lighter);
      border-right: 1px solid var(--base-lighter);
    }
  }
}
// tempfix for sass duplicate import, will revert back after uswds 3.0 is released
// @include at-media('desktop')
@media only screen and (min-width: 1024px) {
  .usa-header.usa-header--extended {
    .usa-logo.afp-logo {
      margin-top: 2rem;
      img {
        width: $logo-width;
        height: $logo-height;
      }
    }
    .usa-header--user-menu {
      align-items: flex-end;
      margin-bottom: 2rem;
      min-height: 0;
      height: auto;
      border: none;
    }
  }
}
