// FIXME: Temporary solution to utilize USWDS
// Mixin and function.
// Follow USWDS guideline: https://designsystem.digital.gov/documentation/developers/
@import '@uswds/dist/scss/uswds';

// custom .usa-button--unstyled
@mixin unstyled-no-link($style) {
  @include u-text($style);
  @include u-text('black');
  @include u-text('no-underline');
}

$border-color-dark: 'ink';

.afp-responsive-table {
  // Horizontal
  .cell-right {
    @include u-text('right');
  }

  .cell-center {
    @include u-text('center');
  }

  .cell-left {
    @include u-text('left');
  }

  // Vertical
  .cell-top {
    @include u-text('top');
  }

  .cell-middle {
    @include u-text('middle');
  }

  .cell-bottom {
    @include u-text('bottom');
  }

  button.table__expand_button--mobile {
    display: none;
  }

  thead {
    tr {
      .table__icon-wrapper {
        display: flex;
        align-items: flex-end;
      }
      @include u-border-bottom(2px, $border-color-dark);
      
      th,
      td {
        @include u-border-bottom(0);
        @include u-text('bottom');
        font-size: 14px;
        font-weight: 700;
        line-height: 17.5px;
        height: auto;
        padding: 8px 10px 8px 10px; 

        &.table-header__sortable:hover {
          @include u-bg('blue-5');
        }
        
        &.table-header__sortable:focus {
          @include u-bg('blue-5');
          outline-offset: -0.25rem;
        }

        .table-header__wrapper {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
        }

        &.cell-expander {
          min-width: 45px;
          max-width: 45px;
          padding: 20px 10px;
        }

        &.cell-selection {
          min-width: 45px;
          max-width: 50px;
          padding: 12px 10px;

          .usa-checkbox__label {
            padding-left: 0;
            margin-top: -8px;
          }
        }
      }
    }
  }

  tbody {
    tr {
      @include u-text('top');
      th,
      td {
        padding: 20px 10px 20px 30px;
        @include u-border-bottom(1px, 'base-light');
        vertical-align: top;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.4;

        > button, > a {
          padding: 0 !important;
          margin: 0 !important;
        }

        button.table__expand_button {
          @include unstyled-no-link('bold');
          @include u-text('center');
          width: 25px;
          height: 25px;
          padding: 0;
          
          &::before {
            display: none;
          }
        }

        &.cell-expander {
          min-width: 45px;
          max-width: 45px;
          padding: 18px 10px;
        }

        &.cell-selection {
          min-width: 45px;
          max-width: 50px;
          padding: 8px 10px;

          .usa-checkbox__label {
            padding-left: 0;
          }
        }

        // useful to align with header if column header is sortable
        // if column sortable = false, don't add class
        &.cell-right-padding {
          @include u-padding-right('205');
        }

      }

      th {
        vertical-align: inherit;
      }

      &.table__expanded-row,
      &.table__selected-row {
        td,
        th {
          @include u-bg('blue-5');
        }
      }

      &.table__expanded-row {
        td,
        th {
          @include u-border-bottom(1px, 'blue-5');
        }
      }

      &.table__sub-component_row {
        td,
        th {
          @include u-padding-x(10);
          @include u-bg('blue-5');
          // FIXME: Use USWDS mixin or function.
          background-color: rgb(239, 246, 251, 0.3);
        }
      }

      .sub-component {
        @include grid-row;
        // @include u-margin-left(4);
      }

      .table__sub-component_cell {
        white-space: normal;
      }
    }
  }

  // Size below tablet
  @include at-media-max('tablet') {
    &.usa-table--stacked {
      @include table-stacked-styles;

      button.table__expand_button {
        display: none;
      }
      button.table__expand_button--mobile {
        @include u-margin-right(0);
        display: block;
      }

      tbody {
        tr {
          @include u-border-bottom(0);

          // button.table__expand_button {
          //   @include
          // }

          &.table__expanded-row {
            th,
            td {
              @include u-padding(0);
              @include u-border-bottom(1px, 'base-light');
              @include u-bg('blue-5');
            }
          }

          &.table__sub-component_row {
            @include u-bg('blue-5');
            th,
            td {
              display: block;
            }
          }
        }

        // Set alternating bg.
        [data-row-type='odd'] {
          th,
          td {
            background-color: var(--theme-table-stripe-background-color);
          }
        }

        th,
        td {
          @include u-padding('105', '!important');
          @include u-border-top(0);
          @include u-border-bottom(1px, 'base-light');

          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
        }

        .sub-component {
          @include u-margin-x(0);
        }
      }
    }
  }

  // Table Row Action  Style
  .dropdown {
    .dropdown-button {
      @include u-text('no-underline');
      font-weight: bold;
      @include u-padding(0);
    }

    position: relative;
    display: inline-block;

    /* Dropdown Content (Hidden by Default) */
    .dropdown-content {
      display: none;
      position: absolute;
      background: #ffffff;
      border: 1px solid #71767a;
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.128223);
      z-index: 1;
      right: 0;
      text-align: left;
      min-width: max-content;
      margin-left: 10px;

      .dropdown-menu-item:hover {
        background-color: #f0f0f0;
      }

      button {
        @include u-text('no-underline');
        &:hover {
          color: #000;
        }
      }
    }

    .show {
      display: block;
    }
  }
}

.afp-responsive-input {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.icon-font {
  font-size: 24px;
}

.icon-visibility {
  visibility: hidden !important;
}

.afp-table-container--scrollable {
  overflow-x: auto;
  clear: both;
}

.afp-responsive-table--total-row {
  tbody {
    tr {
      &:last-child {
        td,
        th {
          @include u-text('bold');
          @include u-bg('gray-3');
          @include u-border-bottom(2px, $border-color-dark);
        }
      }
      &:nth-last-child(2) {
        td,
        th {
          @include u-border-bottom(1px, $border-color-dark);
        }
      }
    }
  }
}
