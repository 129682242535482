.afp-notification-center {
  padding-top: 50px;
  padding-bottom: 50px;

  .afp-notification__body {

    .usa-alert__text {
      padding-top: 0;
    }
  }

  &__heading {
    margin: 0;
  }
}
