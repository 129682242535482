.afp-filter {
  &__closeBtn {
    display: flex;
    text-decoration: none !important;
    color: #000 !important;
  }

  &__toggleBtn {
    padding: 6px !important;
    max-width: 9.375rem;
    height: 1.875rem;
  }
  &__resetBtn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    height: 3.1875rem;
  }
}

.afp-filter .usa-accordion__content {
  /* Hack to unset accordion item overflow*/
  overflow: unset;
}

.afp-filter .opts-container {
  /* this is similar to USWDS mobile-lg */
  min-width: 23.6875rem;
}

.mobile_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #fff;
  padding: 1rem;
  z-index: 100;
  overflow-y: scroll;
}

@media only screen and (max-width: 640px) {
  .afp-filter .opts-container {
    // adjusts typeAhead options dropdown for mobile
    min-width: 18.8125rem !important;
  }
}
