$tag-padding-x: 0.5rem;
$tag-padding-y: 0rem;
$tag-dot-size: 0.75rem;
$tag-dot-gap: 0.375rem;
$tag-text-indent: calc(0rem - #{$tag-dot-size} - #{$tag-dot-gap});

.afp-status-tag {
  display: inline-block;
  border-radius: 1rem;
  padding: $tag-padding-y $tag-padding-x $tag-padding-y
    calc(#{$tag-padding-x} - #{$tag-text-indent});
  text-indent: $tag-text-indent;
  color: initial;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
.afp-status-tag__dot {
  display: inline-block;
  height: $tag-dot-size;
  width: $tag-dot-size;
  border-radius: 50%;
  margin-right: $tag-dot-gap;
}
