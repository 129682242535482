.space-holder {
  display: block;
}

// tempfix for sass duplicate import, will revert back after uswds 3.0 is released
// @include at-media-max('tablet')
@media only screen and (max-width: 640px) {
  .space-holder {
    display: none;
  }
}

.Footer_link_color {
  color: white !important;

  &:hover {
    color: var(--base-lighter) !important;
  }
}

.afp-icon-adjust {
  position: 'relative';
  top: 0.15rem;
  padding-right: 0.05rem;
}
.afp-footer-email {
  text-decoration: underline;
  color: var(--primary) !important;
}

.afp-footer__links {
  row-gap: 0.75rem;
}
